var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pricing-section side-padd", attrs: { id: "pricing" } },
    [
      _c("h2", { staticStyle: { "font-size": "150%" } }, [
        _vm._v(
          "PICKL: A complimentary, flexible vehicle for marketing, sales & consumer insight teams."
        )
      ]),
      _c(
        "div",
        { staticClass: "pricing-table" },
        [
          _vm._l(_vm.picklPacks, function(pack) {
            return _c(
              "div",
              {
                key: pack.id,
                staticClass: "plan",
                class: [pack.best_value == 1 ? "featured" : ""]
              },
              [
                pack.best_value == 1
                  ? _c("div", { staticClass: "extra-info" }, [
                      _c("h5", [_vm._v("Best Value")])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "plan-header" }, [
                  _c("h3", { staticClass: "plan-title" }, [
                    _vm._v(_vm._s(pack.name))
                  ]),
                  pack.name == "Essential"
                    ? _c(
                        "div",
                        {
                          staticClass: "plan-details",
                          staticStyle: { background: "none" }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "Everything you need to try PICKL & start gathering insights"
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  pack.name == "Supreme"
                    ? _c(
                        "div",
                        {
                          staticClass: "plan-details",
                          staticStyle: { background: "none" }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "PICKL power for nationwide campaigns & team collaboration "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  pack.name == "Pro"
                    ? _c(
                        "div",
                        {
                          staticClass: "plan-details",
                          staticStyle: { background: "none" }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "Scale your brand, increase efficiency & engage consumers with premium features "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "plan-cost" }, [
                    _c("small", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Starts at")
                    ]),
                    _c("br"),
                    pack.name == "Essential"
                      ? _c("span", { staticClass: "plan-price" }, [
                          _vm._v("\n              $600"),
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "50%",
                                "font-weight": "normal"
                              }
                            },
                            [_vm._v("/mo*")]
                          )
                        ])
                      : _vm._e(),
                    pack.name == "Supreme"
                      ? _c("span", { staticClass: "plan-price" }, [
                          _vm._v("\n              $1,550"),
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "50%",
                                "font-weight": "normal"
                              }
                            },
                            [_vm._v("/mo*")]
                          )
                        ])
                      : _vm._e(),
                    pack.name == "Pro"
                      ? _c("span", { staticClass: "plan-price" }, [
                          _vm._v("\n              $5600"),
                          _c(
                            "small",
                            {
                              staticStyle: {
                                "font-size": "50%",
                                "font-weight": "normal"
                              }
                            },
                            [_vm._v("/mo*")]
                          )
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "plan-type" }, [
                      _vm._v("billed monthly")
                    ])
                  ])
                ]),
                !_vm.subscriptionDetails.current_subscription
                  ? _c(
                      "div",
                      {
                        staticClass: "plan-select",
                        staticStyle: { "margin-bottom": "20px" }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              "data-target": "#payment-popup",
                              href: "javascript:;",
                              "data-toggle": "modal"
                            },
                            on: {
                              click: function($event) {
                                return _vm.checkout(pack)
                              }
                            }
                          },
                          [_vm._v("get Started")]
                        ),
                        pack.name == "Essential"
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bNumberOfPickls,
                                    expression: "bNumberOfPickls"
                                  }
                                ],
                                staticClass: "btn-select-box",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.bNumberOfPickls = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "30" } }, [
                                  _vm._v("30 credits")
                                ]),
                                _c("option", { attrs: { value: "75" } }, [
                                  _vm._v("75 credits")
                                ])
                              ]
                            )
                          : _vm._e(),
                        pack.name == "Supreme"
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.eNumberOfPickls,
                                    expression: "eNumberOfPickls"
                                  }
                                ],
                                staticClass: "btn-select-box",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.eNumberOfPickls = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "100" } }, [
                                  _vm._v("100 credits")
                                ]),
                                _c("option", { attrs: { value: "200" } }, [
                                  _vm._v("200 credits")
                                ]),
                                _c("option", { attrs: { value: "350" } }, [
                                  _vm._v("350 credits")
                                ])
                              ]
                            )
                          : _vm._e(),
                        pack.name == "Pro"
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.pNumberOfPickls,
                                    expression: "pNumberOfPickls"
                                  }
                                ],
                                staticClass: "btn-select-box",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.pNumberOfPickls = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "400" } }, [
                                  _vm._v("400 credits")
                                ]),
                                _c("option", { attrs: { value: "600" } }, [
                                  _vm._v("600 credits")
                                ]),
                                _c("option", { attrs: { value: "800" } }, [
                                  _vm._v("800 credits")
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _c("div", { staticClass: "plan-select" }, [
                      _c(
                        "a",
                        {
                          staticClass: "disabled-start-button",
                          attrs: { disabled: "", href: "javascript:;" }
                        },
                        [_vm._v("Get Started")]
                      )
                    ]),
                pack.name == "Essential"
                  ? _c("ul", { staticClass: "plan-features" }, [
                      _c("li", [_vm._v("Competitor & category insights")]),
                      _c("li", [_vm._v("Drive shoppers to stores & products")]),
                      _c("li", [_vm._v("Automated geo-located campaigns")]),
                      _c("li", [
                        _vm._v("In-store product & marketing verification")
                      ]),
                      _c("li", [_vm._v("Quality control assurance for tasks")]),
                      _c("li", [_vm._v("Secure dashboard & 1st party data")]),
                      _c("li", [
                        _vm._v("Monthly recurring plan with rollover credits")
                      ]),
                      _c("li", [_vm._v("Credits valid for 1 brand")])
                    ])
                  : _vm._e(),
                pack.name == "Supreme"
                  ? _c("ul", { staticClass: "plan-features" }, [
                      _vm._m(0, true),
                      _c("li", [_vm._v("Custom consumer insights ")]),
                      _c("li", [
                        _vm._v("In-store & @home PICKL compaign task options")
                      ]),
                      _c("li", [
                        _vm._v("Age restricted & alcohol focused tasks")
                      ]),
                      _c("li", [_vm._v("Bulk task automation")]),
                      _c("li", [_vm._v("Task optimization program")]),
                      _c("li", [_vm._v("Invite colleagues to your team")]),
                      _c("li", [_vm._v("Free monthly visual data reports")]),
                      _c("li", [
                        _vm._v(
                          "Share task credits/volume pricing with up to 2 partner brands"
                        )
                      ])
                    ])
                  : _vm._e(),
                pack.name == "Pro"
                  ? _c("ul", { staticClass: "plan-features" }, [
                      _vm._m(1, true),
                      _c("li", [_vm._v("First to new beta features")]),
                      _c("li", [
                        _vm._v("Retailer & store location database expansion")
                      ]),
                      _c("li", [
                        _vm._v(
                          "Consumer product discovery & purchase engagements"
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          "Tailored support with designated customer success team"
                        )
                      ]),
                      _c("li", [
                        _vm._v(
                          "Share task credits/volume pricing with up to 4 partner brands"
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          }),
          _vm._m(2)
        ],
        2
      ),
      _c(
        "label",
        {
          staticStyle: {
            "text-align": "center",
            display: "block",
            "margin-top": "40px"
          }
        },
        [
          _vm._v(
            "*Prices displayed in USD, based on 1 or 2 month cancellation policy per individual plan and monthly billing."
          )
        ]
      ),
      _c("PaymentPopup", {
        attrs: {
          amount: _vm.totalAmount,
          numberOfPickls: _vm.numberOfPickls,
          planName: _vm.planName
        },
        on: { paymentSuccessfull: _vm.SubscribePack }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("All "),
      _c("strong", [_vm._v("Essential")]),
      _vm._v(" perks, plus:")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("All "),
      _c("strong", [_vm._v("Supreme")]),
      _vm._v(" perks, plus:")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plan" }, [
      _c("div", { staticClass: "plan-header" }, [
        _c("h3", { staticClass: "plan-title" }, [_vm._v("Enterprise")]),
        _c(
          "div",
          { staticClass: "plan-details", staticStyle: { background: "none" } },
          [
            _c("p", [
              _vm._v(
                "Team up with global campaigns, curated features, competitive & protected categories"
              )
            ])
          ]
        ),
        _c("div", { staticClass: "plan-cost" }, [
          _c("div", { staticClass: "plan-cost" }, [
            _c("small", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Starts at")
            ]),
            _c("br"),
            _c("span", { staticClass: "plan-price" }, [
              _vm._v("\n                $12,000"),
              _c(
                "small",
                {
                  staticStyle: { "font-size": "50%", "font-weight": "normal" }
                },
                [_vm._v("/mo*")]
              )
            ]),
            _c("span", { staticClass: "plan-type" }, [
              _vm._v("billed quarterly")
            ])
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "plan-select",
          staticStyle: { "margin-bottom": "20px" }
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: "",
                "data-target": "#demo-popup",
                "data-toggle": "modal"
              }
            },
            [_vm._v("Contact Sales")]
          ),
          _c("select", { staticClass: "btn-select-box" }, [
            _c("option", { attrs: { value: "1000" } }, [
              _vm._v("1,000+ credits")
            ])
          ])
        ]
      ),
      _c("ul", { staticClass: "plan-features" }, [
        _c("li", [
          _vm._v("All "),
          _c("strong", [_vm._v("Pro")]),
          _vm._v(" perks, plus:")
        ]),
        _c("li", [_vm._v("Team up with global campaigns")]),
        _c("li", [_vm._v("1st to market features")]),
        _c("li", [_vm._v("competitive & protected categories")]),
        _c("li", [_vm._v("Global community expansion")]),
        _c("li", [_vm._v("Loyalty drivers")]),
        _c("li", [_vm._v("Brand monetization opportunities")]),
        _c("li", [_vm._v("Curated features & consumer engagements")]),
        _c("li", [_vm._v("Sustainability & health initiatives")]),
        _c("li", [
          _vm._v(
            "Share task credits/volume pricing with up to 10 partner brands"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }