




























































import Vue from 'vue'
import SubscriptionPacks from '@/components/Brand/SubscriptionPacks.vue'
import NewSubscriptionPacks from '@/components/Brand/NewSubscriptionPacks.vue'
import ContactUsPopup from '@/components/Brand/ContactUsPopup.vue'
import { Prop, Component } from 'vue-property-decorator'

Vue.use(require('vue-moment'))

@Component({
  components: {
    SubscriptionPacks,
    NewSubscriptionPacks,
    ContactUsPopup
  }
})
export default class Subscription extends Vue {
  // public brandSubscriptionDetails = { current_subscription: null }
  public busy: boolean = true

  @Prop() brandSubscriptionDetails!: boolean

  // getCurrentSubscription () {
  //   this.busy = true
  //   this.$store.dispatch('getBrandCurrentSubscription', this.$store.state.userData.brandId).then((response: any) => {
  //     this.brandSubscriptionDetails = response.response.body.data
  //     this.busy = false
  //   }, response => {
  //     this.busy = false
  //   })
  // }
}
