









































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class ContactUsPopup extends Vue {
  public brandName: string = ''
  public name: string = ''
  public email: string = ''
  public phone: string = ''
  public message: string = ''
  public busy: boolean = false
  public sitekey: string = process.env.VUE_APP_GRECAPTCHA_SITE_KEY
  public code = 'US+1'
  public mobile = ''

  contactUs () {
    let params: any = {
      brand_name: this.brandName,
      first_name: this.name,
      message: this.message,
      contact_type: 'BRAND_DASHBOARD_QUERY',
      priority: 'high'
    }
    if (this.phone) params['phone_number'] = this.phone
    if (this.email) params['email'] = this.email
    Vue.http.post('barnd-contact', params).then(
      (response: any) => {
        Object.assign(this.$data, (this.$options.data! as any).apply(this));
        (this.$refs.popupCloseButton as HTMLElement).click()
        this.$notify({ type: 'success', text: 'Thanks for your interest!' })
        this.$emit('success')
        this.busy = false
      },
      (response: any) => {
        this.$store.commit('hideMessage')
        this.$notify({
          type: 'error',
          text: 'An error occurred while submitting...'
        })
        this.busy = false
      }
    )
  }

  demo () {
    this.brandName = this.brandName.trim()
    this.name = this.name.trim()
    this.email = this.email.trim()
    this.phone = this.phone.trim()
    this.message = this.message.trim()
    this.mobile = this.code.substr(2) + this.phone
    if (!this.mobile.startsWith('+')) this.mobile = '+' + this.mobile
    this.mobile = this.mobile.split('-').join('')
    if (!this.email && !this.phone) {
      this.$notify({
        type: 'error',
        text: 'Please enter either email or phone number'
      })
    } else {
      var phoneRegex = /^[+][0-9]{3}[0-9]{6,10}$/
      if (
        !phoneRegex.test(this.phone) &&
        (!this.email || (this.email && this.phone))
      ) {
        this.$notify({
          type: 'error',
          text: 'Please enter a valid phone number!'
        })
      } else if (this.brandName && this.name) {
        this.busy = true
        this.$store.commit('showLoading')
        this.contactUs()
        // (this.$refs.demoCaptcha as VueRecaptcha).execute()
      } else {
        this.$notify({
          type: 'error',
          text: 'Please fill out all the required fields'
        })
      }
    }
  }
}
